// src/App.tsx
import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Layout from './components/layout/Layout';

// Lazy load pages
const HomePage = React.lazy(() => import('./pages/HomePage/HomePage'));
const FeaturesPage = React.lazy(() => import('./pages/FeaturesPage/FeaturesPage'));
const AboutPage = React.lazy(() => import('./pages/AboutPage/AboutPage'));
const ContactPage = React.lazy(() => import('./pages/ContactPage/ContactPage'));
const DashboardPage = React.lazy(() => import('./pages/DashboardPage/DashboardPage'));

const LoadingFallback = () => (
  <div className="min-h-screen flex items-center justify-center">
    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary"></div>
  </div>
);

const App: React.FC = () => {
  const location = useLocation();
  
  return (
    <Layout>
      <React.Suspense fallback={<LoadingFallback />}>
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<HomePage />} />
            <Route path="/features" element={<FeaturesPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/dashboard" element={<DashboardPage />} />
          </Routes>
        </AnimatePresence>
      </React.Suspense>
    </Layout>
  );
};

export default App;