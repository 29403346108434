// src/components/layout/Layout.tsx
import React from 'react';
import { LayoutProps } from '../../types';
import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';

const Layout: React.FC<LayoutProps> = ({ 
  children, 
  hideNavbar = false, 
  hideFooter = false 
}) => {
  return (
    <div className="min-h-screen flex flex-col">
      {!hideNavbar && <Navbar />}
      <main className="flex-grow">{children}</main>
      {!hideFooter && <Footer />}
    </div>
  );
};

export default Layout;